import React from 'react';

function App() {
  return (
    <div style={styles.container}>
      <iframe 
        title="Audius Player"
        width="100%" 
        height="100%" 
        frameBorder="0" 
        allow="autoplay" 
        src="https://audius.co/opensauce/open-sauce?app_name=Audioverse" // Replace this with the song URL
        style={styles.iframe}
      ></iframe>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    margin: 0,
    padding: 0,
    backgroundColor: '#000',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
};

export default App;
